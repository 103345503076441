<template>
    <section class="content">
        <div class="alert alert-warning" style="font-size:14px;color: white;" role="alert">
            <strong><i class="fas fa-info-circle" style="font-size:16px;"></i>&nbsp;Perhatian!</strong>
            Saat ini anda belum tergabung ke dalam program belajar di Taruna Cendekia. Silahkan lengkapi formulir berikut untuk registrasi.
        </div>
        <div class="row">
            <div class="col-sm-12 col-xl-4 mb-4" v-for="item in programList" :key="item.id">
                <div class="card h-100 mb-2" v-if="item.harga > 0">
                   <div class="card-body">
                      <div class="d-flex justify-content-between align-items-center">
                         <h3 class="mb-0 text-title">{{item.name}}</h3>
                      </div>
                      <hr>
                      <h4 class="text-center"><del>Rp {{formatCurrency(parseInt(item.harga)+1000000)}}</del></h4>
                      <h3 class="text-center h1 mb-3">Rp {{formatCurrency(item.harga)}}</h3>
                      <hr>
                      <button v-on:click="completeForm(item.id)" class="btn btn-primary w-100" style="min-height: 50px; font-weight: 600;"><i class="mdi mdi-cart text-white icon-md"></i> BELI SEKARANG </button>
                      <hr>
                      <ul class="list-unstyled" v-html="item.deskripsi"></ul>
                   </div>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
    import VueHorizontalList from "vue-horizontal-list";
    import { auth, authFetch } from "@/libs/hxcore";
    import moment from "moment";
    import $ from 'jquery';
    export default {
    name: 'Home',
    components: {
        VueHorizontalList,
      },
    data() {
        return {
            imgUrl: 'noimg',
            programList: []
        };
    },
    created: function () {
        this.url = window.$apiUrl;
        var user = auth.user();

        authFetch('/masterdata/siswa/info/'+user.id)
            .then(res => {
                if (res.status === 201) {

                } else if (res.status === 600) {}
                return res.json();
            })
            .then(js => {
                this.info = js.data;
            })

        authFetch('/masterdata/siswa_register/program_online_app')
            .then(res => {
                if (res.status === 201) {

                } else if (res.status === 400) {}
                return res.json();
            })
            .then(js => {
                js.data.map(function (val, key) {
                    if (val.deskripsi) {
                        var split = val.deskripsi.split('\n');
                        var text = '';
                        split.map(function (v,k) {
                            text += '<li style="line-height: 1.8rem;"><i class="fas fa-check mr-2 h4" style="vertical-align: middle; vertical-align: -webkit-baseline-middle; color: #01ADEF"></i><span class="list-text mb-2 fw-600 ">'+v+'</span></li>';
                        })
                        js.data[key].deskripsi = text;
                    }
                })
                this.programList = js.data;
            })
    },
    methods: {
        completeForm: function(program) {
            this.$router.push("/complete-form/" + program);
        },
        formatCurrency: function(value, comma) {
            if(!comma)comma=0;
              let val = (value / 1).toFixed(comma).replace(".", ",");
              return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
        }
    },
}
</script>
<style type="text/css">
    .text-title {
        text-align: center;
        width: 100%;
    }
    .list-text {
        font-size: .875rem;
        font-weight: 600 !important;
        vertical-align: middle;
    }
</style>